import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ReactLoading from "react-loading";
import { geteventdetails } from "../../services/opencorporates/Lookup";
import Moment from "moment";

export default function Event(props) {
  const { id } = useParams();
  const history = useHistory();
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (!id) {
        history.push("/businesslookup/index");
        window.location.reload();
    } else {
        geteventdetails(id).then((response) => {
            if (response.status == 200) {
                // console.log("response: ", response);
                setData(response.data.results.event);
                setDataLoaded(true);
            }
        }).catch((error) => {
            console.log("error: ", error);
        });
    }
  }, []);
  
  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
                <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                    <div className="w-full flex items-center flex-wrap justify-between">
                        <div
                            className="px-4"
                            style={{ paddingLeft: "0" }}
                        >
                            <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                                {data.description ? data.description : "N/A"}
                            </h4>
                        </div>
                    </div>
                </div>

                <div id="eventRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="block w-full overflow-x-auto pb-6">
                        <div
                            className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                        >
                            <div className="flex">
                                <div
                                    className="px-4 mr-4 cursor-pointer"
                                    style={{ paddingLeft: "0" }}
                                >
                                    <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                        Event Details
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between flex-wrap w-full px-12">
                            <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                <div className="flex flex-col idType">
                                    <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                        {/* <h4 className="font-bold text-sm mb-2 flex flex-row">
                                            <p className="w-full">Basic Details</p>
                                        </h4> */}
                                        <table className="w-full table-fixed">
                                            <tbody>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Created at
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.created_at ? Moment(data.created_at).format("MM-DD-YYYY hh:mm a") : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Event Type
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.type ? data.type : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Event Class
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.event_class ? data.event_class : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Date Range
                                                    </td>
                                                    {data.time_range ? 
                                                        <>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.time_range.starts_at ? Moment(data.time_range.starts_at).format("MM-DD-YYYY hh:mm a") : "N/A"} - {data.time_range.ends_at ? Moment(data.time_range.ends_at).format("MM-DD-YYYY hh:mm a") : "N/A"}
                                                            </td>
                                                        </>
                                                        :
                                                        <td className="font-semibold text-xs text-right">
                                                            N/A
                                                        </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Category(s)
                                                    </td>
                                                    {data.categories.length > 0 ? 
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.categories.join(", ")}
                                                        </td>
                                                        :
                                                        <td className="font-semibold text-xs text-right">
                                                            N/A
                                                        </td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#ee416f",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}
    </>
  );
}