import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from 'react-select-country-list';
import { saveevent } from "../../services/kyc/KycScreens";
import { savecompanydocs } from "../../services/kyb/KybScreens";
import {KycMobContext} from "../../layouts/KYCMob"
import { getacceptablecountries } from "../../services/settings/Acceptable";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { addDays } from "date-fns";
import {isMobile} from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import uploadIcon from " ../../assets/img/kycmobile/uploadIcon.png";

export default function Screen3docs() {
  const inputFile1 = React.useRef(null);
  const inputFile2 = React.useRef(null);
  const inputFile3 = React.useRef(null);
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3docs) {
        const redirecturl = "/kyb/e/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kyb/e/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const fileUpload1 = () => {
    inputFile1.current.click();
  }
  const fileUpload2 = () => {
    inputFile2.current.click();
  }
  const fileUpload3 = () => {
    inputFile3.current.click();
  }
  
  const [formdata, setFormdata] = useState({
    coa: null,
    aoa: null,
    al: null,
    inq_id: url
  });

  const handleRemoveFile1 = () => {
    setFormdata((values) => ({ ...values, ["coa"]: null }));
  }
  const handleRemoveFile2 = () => {
    setFormdata((values) => ({ ...values, ["aoa"]: null }));
  }
  const handleRemoveFile3 = () => {
    setFormdata((values) => ({ ...values, ["al"]: null }));
  }

  const handleChange1 = (e) => {
    setMessage("");
    if (e.target.files) {
      const [f] = e.target.files;
      let MAX_FILE_SIZE = 10240;

      const fileSizeKiloBytes = f.size / 1024;

      if (f.type === "application/pdf" || f.type === "image/jpeg" || f.type === "image/jpg" || f.type === "image/png") {
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          setMessage("File size is greater than maximum limit");
          return
        }
    
        setFormdata((values) => ({ ...values, ["coa"]: f }));
      } else {
        setMessage("Allowed file formats are pdf, jpg, jpeg, png");
        return
      }
    }
  };

  const handleChange2 = (e) => {
    setMessage("");
    if (e.target.files) {
      const [f] = e.target.files;
      let MAX_FILE_SIZE = 10240;

      const fileSizeKiloBytes = f.size / 1024;

      if (f.type === "application/pdf" || f.type === "image/jpeg" || f.type === "image/jpg" || f.type === "image/png") {
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          setMessage("File size is greater than maximum limit");
          return
        }
    
        setFormdata((values) => ({ ...values, ["aoa"]: f }));
      } else {
        setMessage("Allowed file formats are pdf, jpg, jpeg, png");
        return
      }
    }
  };

  const handleChange3 = (e) => {
    setMessage("");
    if (e.target.files) {
      const [f] = e.target.files;
      let MAX_FILE_SIZE = 10240;

      const fileSizeKiloBytes = f.size / 1024;
  
      if (f.type === "application/pdf" || f.type === "image/jpeg" || f.type === "image/jpg" || f.type === "image/png") {
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          setMessage("File size is greater than maximum limit");
          return
        }
    
        setFormdata((values) => ({ ...values, ["al"]: f }));
      } else {
        setMessage("Allowed file formats are pdf, jpg, jpeg, png");
        return
      }
    }
  };

  const handleSubmit = () => {
    setMessage("");

    if (formdata.coa === null) {
      setMessage("Certificate of Incorporation is required!");
      return;
    }

    // console.log("formdata: ", formdata);
    setDisable(true);
    setMessage("Please wait while documents are uploading...");
    savecompanydocs(formdata)
      .then((response) => {
        if (response.data.success) {
          let formdata3 = {
            inq_id: url,
            event_desc: "Company Documents Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen4 = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kyb/g/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        } else {
          setDisable(false);
          setMessage("There was an error with document processing, please try again");
        }
      })
      .catch((error) => {
        setDisable(false);
        setMessage("There was an error with document processing, please try again");
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kyb/e/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Please attach company documents.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="lastNameField mb-4">
              <span className="text-sm text-left font-bold">Certificate of Incorporation<b>*</b></span>
              <input type="file" ref={inputFile1} style={{display: "none"}} onChange={handleChange1}/>
              {formdata.coa ? 
                <div className="uploadFileButton">
                  <p className="text-xs text-center w-full"><span>{formdata.coa.name}</span> <b onClick={handleRemoveFile1} className="float-right">X</b></p>
                </div>
                :
                <div className="uploadFileButton" onClick={fileUpload1}>
                  <p className="text-xs text-center w-full">Upload a File</p>
                </div>
              }
              <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Max file size 10MB)</p>
            </div>

            <div className="lastNameField mb-4">
              <span className="text-sm text-left font-bold">Articles of Association / Memorandum of Association / Bylaws</span>
              <input type="file" ref={inputFile2} style={{display: "none"}} onChange={handleChange2}/>
              {formdata.aoa ? 
                <div className="uploadFileButton">
                  <p className="text-xs text-center w-full"><span>{formdata.aoa.name}</span> <b onClick={handleRemoveFile2} className="float-right">X</b></p>
                </div>
                :
                <div className="uploadFileButton" onClick={fileUpload2}>
                  <p className="text-xs text-center w-full">Upload a File</p>
                </div>
              }
              <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Max file size 10MB)</p>
            </div>
            
            <div className="lastNameField">
              <span className="text-sm text-left font-bold">Authorization Letter</span>
              <input type="file" ref={inputFile3} style={{display: "none"}} onChange={handleChange3}/>
              {formdata.al ? 
                <div className="uploadFileButton">
                  <p className="text-xs text-center w-full"><span>{formdata.al.name}</span> <b onClick={handleRemoveFile3} className="float-right">X</b></p>
                </div>
                :
                <div className="uploadFileButton" onClick={fileUpload3}>
                  <p className="text-xs text-center w-full">Upload a File</p>
                </div>
              }
              <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Max file size 10MB)</p>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
