import axios from "axios";
import address from "../Address";

export function search(data) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        if (data.country == "") {
            return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/companies/search", {
                params: {
                  q: data.name,
                  fields: "name,previous_names",
                  normalise_company_name: true,
                  per_page: data.numOfRecords,
                  page: data.pageNum,
                  api_token: process.env.REACT_APP_OPEN_CORPORATES_KEY
                }
            });
        } else {
            return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/companies/search", {
                params: {
                  q: data.name,
                  fields: "name,previous_names",
                  normalise_company_name: true,
                  per_page: data.numOfRecords,
                  page: data.pageNum,
                  jurisdiction_code: data.country,
                  api_token: process.env.REACT_APP_OPEN_CORPORATES_KEY
                }
            });
        }
    } else {
        return false;
    }
}

export function getdetails(data) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/companies/" + data.jurisdiction_code + "/" + data.company_number + "?api_token=" + process.env.REACT_APP_OPEN_CORPORATES_KEY);
    } else {
        return false;
    }
}

export function getfillings(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/filings/" + id + "?api_token=" + process.env.REACT_APP_OPEN_CORPORATES_KEY);
    } else {
        return false;
    }
}

export function getcompanyevents(data) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/companies/" + data.jurisdiction_code + "/" + data.company_number + "/events" + "?api_token=" + process.env.REACT_APP_OPEN_CORPORATES_KEY);
    } else {
        return false;
    }
}

export function geteventdetails(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(process.env.REACT_APP_OPEN_CORPORATES_URL + "/events/" + id + "?api_token=" + process.env.REACT_APP_OPEN_CORPORATES_KEY);
    } else {
        return false;
    }
}

export function getcoveragedata() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kyb/coverage", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}