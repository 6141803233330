import React, { useCallback } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import "react-circular-progressbar/dist/styles.css";
import { Link, useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import createRule from "../../assets/img/createRule.png";
import Select from "react-select";
import countryList from "react-select-country-list";
import AMLScreeningTable from "../admin/AMLScreeningTable";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";

import inquirySearch from "../../assets/img/inquirySearch.png";
import dropDownIcon from "../../assets/img/dropdown.png";

export default function AMLScreeningInquire() {
  const [filecsv, setFileCsv] = React.useState(null);

  const [datadone, setDatadone] = React.useState(false);
  const [checks, setChecks] = React.useState([]);
  const [tableKey, setTableKey] = React.useState(0);
  const [inquireShow, setInquireShow] = React.useState(true);

  const DropzoneCSV = (props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: {
        "text/csv": [".csv"],
      },
      onDrop: (files) => setFileCsv(files),
    });

    return (
      <section className="container dropzoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <button type="button" class="dropzoneSelectButton">
            Choose Files
          </button>
          <p className="font-xs">or drag file here</p>
        </div>
        <aside>
          {/* <h4>Files</h4> */}
          <ul>
            <li>
              {filecsv
                ? filecsv[0].path + " - " + filecsv[0].size + "bytes"
                : ""}
            </li>
          </ul>
        </aside>
      </section>
    );
  };

  const history = useHistory();
  
  const options = React.useMemo(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  React.useEffect(() => {
    let flag = false;
    options.forEach((ele) => {
      if(ele.label === "Select Country") {
        flag = true;
      }
    });
    if (!flag) {
      options.unshift({
        value: "",
        label: "Select Country",
      });
    }
  }, []);

  const [data, setData] = React.useState(
    {
      name: "",
      nationality: "",
      birthYear: "",
      identifier: "",
      country: "",
    },
  );
  const [birthYear, setBirthYear] = React.useState("");
  const [country, setCountry] = React.useState({
    value: "",
    label: "Select Country",
  });
  const [message, setMessage] = React.useState("");

  const [sanctionCheck, setSanctionCheck] = React.useState(true);
  const [sanctionChecks, setSanctionChecks] = React.useState([true]);

  const [pepCheck, setPepCheck] = React.useState(true);
  const [pepChecks, setPepChecks] = React.useState([
    true,
    true,
    true,
    true,
    true,
  ]);

  const [adversemediaCheck, setAdversemediaCheck] = React.useState(true);
  const [adversemediaChecks, setAdversemediaChecks] = React.useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const sanctionSelectAll = () => {
    let all = !sanctionCheck;

    const checks = sanctionChecks.map((element, index) => {
      return all;
    });

    setSanctionCheck(all);
    setSanctionChecks(checks);
  };

  const sanctionCheckChanged = (ind) => {
    const checks = sanctionChecks.map((element, index) => {
      if (index === ind) {
        return !element;
      } else {
        return element;
      }
    });

    setSanctionChecks(checks);
    let allchecked = false;

    if (checks.includes(false)) {
      allchecked = false;
    } else {
      allchecked = true;
    }

    if (allchecked) {
      setSanctionCheck(true);
    } else {
      setSanctionCheck(false);
    }
  };

  const pepSelectAll = () => {
    let all = !pepCheck;

    const checks = pepChecks.map((element, index) => {
      return all;
    });

    setPepCheck(all);
    setPepChecks(checks);
  };

  const pepCheckChanged = (ind) => {
    const checks = pepChecks.map((element, index) => {
      if (index === ind) {
        return !element;
      } else {
        return element;
      }
    });

    setPepChecks(checks);
    let allchecked = false;

    if (checks.includes(false)) {
      allchecked = false;
    } else {
      allchecked = true;
    }

    if (allchecked) {
      setPepCheck(true);
    } else {
      setPepCheck(false);
    }
  };

  const adversemediaSelectAll = () => {
    let all = !adversemediaCheck;

    const checks = adversemediaChecks.map((element, index) => {
      return all;
    });

    setAdversemediaCheck(all);
    setAdversemediaChecks(checks);
  };

  const adversemediaCheckChanged = (ind) => {
    const checks = adversemediaChecks.map((element, index) => {
      if (index === ind) {
        return !element;
      } else {
        return element;
      }
    });

    setAdversemediaChecks(checks);
    let allchecked = false;

    if (checks.includes(false)) {
      allchecked = false;
    } else {
      allchecked = true;
    }

    if (allchecked) {
      setAdversemediaCheck(true);
    } else {
      setAdversemediaCheck(false);
    }
  };

  const [individualActive, setIndividualActive] = React.useState(true);
  const [batchActive, setBatchScreenActive] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(1);

  function setIndivActive() {
    setInquireShow(true);
    setIndividualActive(true);
    setBatchScreenActive(false);
  }

  function setBatchActive() {
    setInquireShow(true);
    setIndividualActive(false);
    setBatchScreenActive(true);
  }

  const handleChangeSelectCountry = (event) => {
    // const name = "country";
    // const newData = data.map((el, idx) => {
    //   if (idx === arrayIndex) {
    //     return {
    //       ...el,
    //       [name]: event,
    //     };
    //   }
    //   return el;
    // });
    setCountry(event);
    // let newData = data;
    // newData[name] = event;

    // console.log(event);
    // console.log(newData);
    // setData(newData);
  };

  const handleDataChange = (event) => {
    // console.log(event.target.value);
    // console.log(arrayIndex);
    const { value, name } = event.target;
    // const newData = data.map((el, idx) => {
    //   if (idx === arrayIndex) {
    //     return {
    //       ...el,
    //       [name]: value,
    //     };
    //   }
    //   return el;
    // });
    let newData = data;
    newData[name] = value;
    setData(newData);
  };

  const handleSubmit = (event) => {
    data.country = country;
    data.birthYear = birthYear;
    setTableKey((key) => key + 1);
    setDatadone(false);
    
    let checkdata = [
      sanctionChecks[0] ? "sanction" : "",
      pepChecks[0] ? "role.pep" : "",
      pepChecks[1] ? "debarment" : "",
      pepChecks[2] ? "fin.bank" : "",
      pepChecks[3] ? "role.oligarch" : "",
      pepChecks[4] ? "role.rca" : "",
      adversemediaChecks[0] ? "crime" : "",
      adversemediaChecks[1] ? "crime.boss" : "",
      adversemediaChecks[2] ? "crime.fin" : "",
      adversemediaChecks[3] ? "crime.terror" : "",
      adversemediaChecks[4] ? "crime.theft" : "",
      adversemediaChecks[5] ? "crime.war" : "",
    ];
    data.checks = checkdata.filter((e) => e);

    setMessage("");
    let emptyRow = true;

    if (data.name == "") {
      emptyRow = true;
      setMessage("Please enter a valid name");
    } else {
      emptyRow = false;
      setMessage("");
    }

    if (!emptyRow) {
      setDatadone(true);
      setInquireShow(false);
    }
  };

  const handleSubmitBatch = (event) => {
    setTableKey((key) => key + 1);
    setDatadone(false);
    let checkdata = [
      sanctionChecks[0] ? "sanction" : "",
      pepChecks[0] ? "role.pep" : "",
      pepChecks[1] ? "debarment" : "",
      pepChecks[2] ? "fin.bank" : "",
      pepChecks[3] ? "role.oligarch" : "",
      pepChecks[4] ? "role.rca" : "",
      adversemediaChecks[0] ? "crime" : "",
      adversemediaChecks[1] ? "crime.boss" : "",
      adversemediaChecks[2] ? "crime.fin" : "",
      adversemediaChecks[3] ? "crime.terror" : "",
      adversemediaChecks[4] ? "crime.theft" : "",
      adversemediaChecks[5] ? "crime.war" : "",
    ];
    setChecks(checkdata.filter((e) => e));
    // checks =  checkdata.filter(e =>  e);
    setDatadone(true);
    setInquireShow(false);
    // history.push({
    //   pathname: '/aml/amlscreeninglist',
    //   state: { file: filecsv, data: data, checks: checks, filecheck: true, individualscreening: false, batchscreening: true }
    // });
  };

  const deletePerson = (event, arrayIndex) => {
    const newData = data.filter((el, idx) => idx !== arrayIndex);
    setData(newData);
    setRowCount(rowCount - 1);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      paddingTop: 0,
    }),
  };

  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex">
                <div
                  className="rounded-lg px-4 py-2 mr-4 cursor-pointer"
                  style={
                    individualActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={setIndivActive}
                >
                  <h4 className="font-semibold text-xs">
                    Individual Screening
                  </h4>
                </div>
                <div
                  className="rounded-lg px-4 py-2 cursor-pointer"
                  style={
                    batchActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={setBatchActive}
                >
                  <h4 className="font-semibold text-xs">Batch Screening</h4>
                </div>
              </div>
            </div>
            {individualActive && (
              <div className="w-full flex flex-row">
                <div className="lg:w-12/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                    <div
                      className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                      onClick={() => setInquireShow(!inquireShow)}
                    >
                      <div className="flex">
                        <div
                          className="px-4 mr-4 cursor-pointer"
                          style={{ paddingLeft: "0" }}
                        >
                          <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                            Search Name and Details
                          </h4>
                        </div>
                      </div>
                      <div className="flex px-4 py-2 cursor-pointer">
                        {!inquireShow && (
                          <img
                            src={dropDownIcon}
                            alt="down icon"
                            className="mr-3"
                            style={{ width: "15px", height: "10px" }}
                          />
                        )}
                        {inquireShow && (
                          <img
                            src={dropDownIcon}
                            alt="down icon"
                            className="mr-3 dropDownIconRotated"
                            style={{ width: "15px", height: "10px" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="rounded-t mb-0 p-8 border-0 amlInquireDiv">
                      <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                          <Form class="inquireForm aml">
                            <div className="flex flex-col items-center">
                              <div className="flex w-full flex-row mb-6 items-center amlInquireMain">
                                {/* <label className="w-full max-w-100-px text-sm">Person {i+1}: </label> */}
                                <div className="lg:w-3/12 md:w-4/12 sm:w-6/12 mr-4">
                                  <div className="searchInputTables">
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Name
                                      </label>
                                      <Input
                                        type="text"
                                        name="name"
                                        className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        style={{ fontSize: "12px" }}
                                        value={data.name}
                                        onChange={(e) =>
                                          handleDataChange(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:w-3/12 md:w-4/12 sm:w-6/12 mr-4">
                                  <div className="searchInputTables">
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Birth Year
                                      </label>
                                      {/* <Input
                                        type="date"
                                        name="birthYear"
                                        max={disableDates()}
                                        className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        style={{ fontSize: "0.75rem" }}
                                        value={data[i].birthYear}
                                        onChange={(e) =>
                                          handleDataChange(e, i)
                                        }
                                      /> */}
                                      <DatePicker
                                        portalId="root-portal"
                                        name="birthYear"
                                        // maxDate={addDays(new Date(), 0)}
                                        className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        selected={birthYear}
                                        style={{ fontSize: "0.75rem" }}
                                        onChange={(value, event) => {
                                          // event.target = {
                                          //   value: value,
                                          //   name:"birthYear"
                                          // };
                                          // handleDataChange(event);
                                          setBirthYear(value);
                                        }}
                                        dropdownMode="select"
                                        showYearPicker
                                        dateFormat="yyyy"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:w-3/12 md:w-4/12 sm:w-6/12 mr-4">
                                  <div className="searchInputTables">
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Identifier
                                      </label>
                                      <Input
                                        type="text"
                                        placeholder={"Passport or ID number"}
                                        name="identifier"
                                        className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        style={{ fontSize: "0.75rem" }}
                                        value={data.identifier}
                                        onChange={(e) =>
                                          handleDataChange(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:w-3/12 md:w-4/12 sm:w-6/12">
                                  <div className="searchInputTables">
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Country
                                      </label>
                                      <Select
                                        name="country"
                                        className="input_Fields"
                                        style={{ all: "unset" }}
                                        placeholder="Search Country"
                                        options={options}
                                        value={country}
                                        onChange={(e) =>
                                          handleChangeSelectCountry(e)
                                        }
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {inquireShow && (
                                <>
                                  {/* <div className="flex w-full flex-row mb-8 mt-8">
                                    <div className="lg:w-12/12 mr-4 cursor-pointer">
                                      <img
                                        style={{ width: "80%" }}
                                        src={inquirySearch}
                                        alt="addInquire"
                                        onClick={addRow}
                                      />
                                    </div>
                                  </div> */}

                                  {message !== "" && (
                                    <div className="flex w-full flex-row mb-4 mt-4">
                                      <div className="lg:w-12/12">
                                        <p
                                          className="text-sm"
                                          style={{ color: "#ff0033" }}
                                        >
                                          {message}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="flex w-full flex-row mb-8 mt-8">
                                    <div className="w-auto mr-16">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="sanctioncheck"
                                              type="checkbox"
                                              checked={sanctionCheck}
                                              defaultChecked={sanctionCheck}
                                              onChange={sanctionSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            Sanctions
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="sanctioncheck"
                                                type="checkbox"
                                                checked={sanctionChecks[0]}
                                                defaultChecked={
                                                  sanctionChecks[0]
                                                }
                                                onChange={() =>
                                                  sanctionCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Global Sanctions e.g. OFAC SDN List, UNSC, HM Treasury Sanctions List
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-auto mr-16">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="pepcheck"
                                              type="checkbox"
                                              checked={pepCheck}
                                              defaultChecked={pepCheck}
                                              onChange={pepSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            PEP Class
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[0]}
                                                defaultChecked={pepChecks[0]}
                                                onChange={() =>
                                                  pepCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              PEP
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[1]}
                                                defaultChecked={pepChecks[1]}
                                                onChange={() =>
                                                  pepCheckChanged(1)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Debarment
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[2]}
                                                defaultChecked={pepChecks[2]}
                                                onChange={() =>
                                                  pepCheckChanged(2)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Bank Related
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[3]}
                                                defaultChecked={pepChecks[3]}
                                                onChange={() =>
                                                  pepCheckChanged(3)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Oligarch
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[4]}
                                                defaultChecked={pepChecks[4]}
                                                onChange={() =>
                                                  pepCheckChanged(4)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Relatives and Close Associates
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-auto mr-32">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="adversemediacheck"
                                              type="checkbox"
                                              checked={adversemediaCheck}
                                              defaultChecked={adversemediaCheck}
                                              onChange={adversemediaSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            Adverse Media
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[0]}
                                                defaultChecked={
                                                  adversemediaChecks[0]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Terrorism - FATF EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[1]}
                                                defaultChecked={
                                                  adversemediaChecks[1]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(1)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Violence AML/CFT - AML/CFT FATF EU AML, NON-AML/CFT
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[2]}
                                                defaultChecked={
                                                  adversemediaChecks[2]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(2)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Property - FATF EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[3]}
                                                defaultChecked={
                                                  adversemediaChecks[3]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(3)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Regulatory
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[4]}
                                                defaultChecked={
                                                  adversemediaChecks[4]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(4)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Cybercrime - EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[5]}
                                                defaultChecked={
                                                  adversemediaChecks[5]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(5)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Other - Financial, Theft, Minor, Serious Crime
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-submit button-action flex w-full flex-row">
                                    <button
                                      onClick={handleSubmit}
                                      type={"button"}
                                      className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                    >
                                      Inquire
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {batchActive && (
              <>
                <div className="w-full flex flex-row">
                  <div className="lg:w-12/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                      <div
                        className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                        onClick={() => setInquireShow(!inquireShow)}
                      >
                        <div className="flex">
                          <div
                            className="px-4 mr-4 cursor-pointer"
                            style={{ paddingLeft: "0" }}
                          >
                            <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                              AML Batch Inquiry
                            </h4>
                            <p className="text-sm">
                              Screen name by uploading spreadsheet using selected
                              column i.e. name, birthYear, country, identifier.
                            </p>
                          </div>
                        </div>
                        <div className="flex px-4 py-2 cursor-pointer">
                          {!inquireShow && (
                            <img
                              src={dropDownIcon}
                              alt="down icon"
                              className="mr-3"
                              style={{ width: "15px", height: "10px" }}
                            />
                          )}
                          {inquireShow && (
                            <img
                              src={dropDownIcon}
                              alt="down icon"
                              className="mr-3 dropDownIconRotated"
                              style={{ width: "15px", height: "10px" }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="rounded-t mb-0 p-8 border-0">
                        <div className="flex flex-wrap items-center">
                          <div className="relative w-full max-w-full flex-grow flex-1">
                            <Form class="inquireForm aml">
                              <div className="flex w-full flex-row mb-6 items-center">
                                <DropzoneCSV />
                              </div>
                              {inquireShow && (
                                <div className="flex flex-col items-center">
                                  <div className="flex w-full flex-row mb-8 mt-8">
                                    <div className="w-auto mr-16">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="sanctioncheck"
                                              type="checkbox"
                                              checked={sanctionCheck}
                                              defaultChecked={sanctionCheck}
                                              onChange={sanctionSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            Sanctions
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="sanctioncheck"
                                                type="checkbox"
                                                checked={sanctionChecks[0]}
                                                defaultChecked={sanctionChecks[0]}
                                                onChange={() =>
                                                  sanctionCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Global Sanctions e.g. OFAC SDN List, UNSC, HM Treasury Sanctions List
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-auto mr-16">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="pepcheck"
                                              type="checkbox"
                                              checked={pepCheck}
                                              defaultChecked={pepCheck}
                                              onChange={pepSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            PEP Class
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[0]}
                                                defaultChecked={pepChecks[0]}
                                                onChange={() =>
                                                  pepCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Debarment
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[1]}
                                                defaultChecked={pepChecks[1]}
                                                onChange={() =>
                                                  pepCheckChanged(1)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Bank Related
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[2]}
                                                defaultChecked={pepChecks[2]}
                                                onChange={() =>
                                                  pepCheckChanged(2)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Oligarch
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[3]}
                                                defaultChecked={pepChecks[3]}
                                                onChange={() =>
                                                  pepCheckChanged(3)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Pep
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="pepcheck"
                                                type="checkbox"
                                                checked={pepChecks[4]}
                                                defaultChecked={pepChecks[4]}
                                                onChange={() =>
                                                  pepCheckChanged(4)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Relatives and Close Associates
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-auto mr-32">
                                      <div className="flex flex-col">
                                        <div className="selectBox">
                                          <label className="inline-flex items-center cursor-pointer text-xs">
                                            <input
                                              id="adversemediacheck"
                                              type="checkbox"
                                              checked={adversemediaCheck}
                                              defaultChecked={adversemediaCheck}
                                              onChange={adversemediaSelectAll}
                                              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                            />
                                            Adverse Media
                                          </label>
                                        </div>
                                        <div className="childcheckboxes flex flex-col ml-6">
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[0]}
                                                defaultChecked={
                                                  adversemediaChecks[0]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(0)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Terrorism - FATF EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[1]}
                                                defaultChecked={
                                                  adversemediaChecks[1]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(1)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Violence AML/CFT - AML/CFT FATF EU AML, NON-AML/CFT
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[2]}
                                                defaultChecked={
                                                  adversemediaChecks[2]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(2)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Property - FATF EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[3]}
                                                defaultChecked={
                                                  adversemediaChecks[3]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(3)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Regulatory
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[4]}
                                                defaultChecked={
                                                  adversemediaChecks[4]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(4)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Cybercrime - EU AML
                                            </label>
                                          </div>
                                          <div className="selectBox">
                                            <label className="inline-flex items-center cursor-pointer text-xs">
                                              <input
                                                id="adversemediacheck"
                                                type="checkbox"
                                                checked={adversemediaChecks[5]}
                                                defaultChecked={
                                                  adversemediaChecks[5]
                                                }
                                                onChange={() =>
                                                  adversemediaCheckChanged(5)
                                                }
                                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                              />
                                              Other - Financial, Theft, Minor, Serious Crime
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-submit button-action flex w-full flex-row">
                                    <button
                                      onClick={handleSubmitBatch}
                                      type={"button"}
                                      className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                    >
                                      Inquire
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}


            {datadone && (
              <AMLScreeningTable
                key={tableKey}
                file={filecsv}
                filecheck={batchActive}
                data={data}
                checks={checks}
                individualscreening={individualActive}
                batchscreening={batchActive}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
